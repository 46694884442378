import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FaLongArrowAltRight } from 'react-icons/fa';

const StyledArticle = styled.article`
	background: ${props => props.theme.colors.white};
	border-radius: ${props => props.theme.border_radius};
	position: relative;
	bottom: 0;
	color: ${props => props.theme.colors.warm_grey_800};
	box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
		0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
	transition: all 0.3s;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			bottom: 3px;
			box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
				0 2px 35px ${props => props.theme.colors.warm_grey_800 && transparentize(0.7, props.theme.colors.warm_grey_800)};
		}
	}
`;

const Image = styled.img`
	width: 100%;
	height: 250px;
	object-fit: cover;
	border-top-left-radius: ${props => props.theme.border_radius};
	border-top-right-radius: ${props => props.theme.border_radius};
`;

const Padding = styled.div`
	padding: 9px 13px 13px;
	text-align: center;
	margin: 0 0 70px;

	h3 {
		margin: 0;
		text-align: left;
	}

	a {
		color: ${props => props.theme.colors.warm_grey_800};
		font-family: 'Playfair Display', serif;
	}
`;

const Categories = styled.span`
	font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
`;

const Title = styled(Link)`
	font-size: 30px;
	font-weight: 700;
	display: block;
	margin: 23px 0;

	&:hover,
	&:focus {
		text-decoration: none;
		outline: none;
	}
`;

const Button = styled(Link)`
	background: ${props => props.theme.colors.warm_grey_100};
	width: 100%;
	display: block;
	text-align: center;
	padding: 23px 0;
	margin-top: 23px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	bottom: 0;
	position: absolute;
	font-weight: 700;
	border-bottom-left-radius: ${props => props.theme.border_radius};
	border-bottom-right-radius: ${props => props.theme.border_radius};
	transition: all 0.3s;

	&:hover,
	&:focus {
		background: ${props => props.theme.colors.golden_500};
		color: ${props => props.theme.colors.white};
		outline: none;
		text-decoration: none;
	}
`;

const BlogPostThumbnail = ({ className, post }) => (
	<StyledArticle className={className}>
		<Link to={`/blog/${post.slug}/`}>
			<Image src={post.photo} alt={post.title} />
		</Link>

		<Padding>
			<h3>
				<Categories>{post.categories.join(', ')}</Categories>
			</h3>

			<Title to={`/blog/${post.slug}/`}>{post.title}</Title>
		</Padding>

		<Button to={`/blog/${post.slug}/`}>
			Czytaj więcej <FaLongArrowAltRight />
		</Button>
	</StyledArticle>
);

export default BlogPostThumbnail;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';
import BlogPostThumbnail from '../components/blog-post-thumbnail';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const Grid = styled.div`
	position: relative;
	z-index: 220;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	grid-gap: ${between('23px', '30px')};
`;

const BlogPage = ({ data }) => {
	return (
		<Page>
			<SEO title="Blog" description="Inspiracje, porady, rankingi i opinie na temat usług weselnych" canonical="blog" />
			<StyledHeaderSection hero="/hero-blog.jpg">
				<HeaderFront>
					<Navigation />
					<Container>
						<HeaderHeading>Blog</HeaderHeading>
						<HeaderDescription>Inspiracje, porady, rankingi i opinie na temat usług weselnych</HeaderDescription>
					</Container>
				</HeaderFront>
			</StyledHeaderSection>

			<Container>
				<Grid>
					{data.allMarkdownRemark.edges.map(({ node }) => (
						<BlogPostThumbnail key={node.frontmatter.slug} post={node.frontmatter} />
					))}
					{data.allMarkdownRemark.edges.length < 2 && <div></div>}
					{data.allMarkdownRemark.edges.length < 3 && <div></div>}
				</Grid>
			</Container>
		</Page>
	);
};

export default BlogPage;

export const query = graphql`
	query BlogPage{
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			edges {
				node {
					frontmatter {
						slug
						title
						categories
						photo
					}
				}
			}
		}
	}
`;
